export enum SignalRNotificationType {
    Unknown = 0,
    ThoughtCreated,
    ThoughtUpdated,
    ThoughtDeleted,
    ThoughtCommentCreated,
    ThoughtCommentUpdated,
    ThoughtCommentDeleted,
    ClassMonitoringStarted,
    ClassMonitoringStopped,
    StartFollowing,
    StopFollowing,
    PageTurn,
    OpenClozeAssessment,
    AssessmentDataChanged,
    ClozeRollInfo,
    AssessmentAnswered,
    SidebarClosed,
    StudentExitsReader,
    OpenQuizAssessment,
    OpenDefinition,
    ReaderSwitched,
    ReaderTextSelected,
    ReaderActionSelected,
    ReaderModalDismissed,
    AnotherDeviceLoggedIn,
    OpenBook,
    StartActiveGroupReadingSession,
    UpdateActiveGroupReadingSession,
    UpdateActiveGroupReading,
    UpdateActiveGroupReadingUser,
    RemoveActiveGroupReadingUser,
    AddedUserToReadingGroup,
    ReadingGroupBadgeAssigned
}
