export enum AssessmentType {
    CLOZE = 1,
    MULTIPLE_CHOICE = 2,
    SHORT_RESPONSE = 3,
    FILL_IN_BLANK = 4,
    POLL = 5,
    SYNTAX = 7,
    DECODABLE_PRE_KNOWLEDGE = 9,
    DECODABLE_POST_KNOWLEDGE = 10,
    DECODABLE_COMPREHENSION = 11,
    DECODABLE_EXTENSION = 12,
    DECODABLE_EXTENSTION_STEM,
    MULTIPLE_CHOICE_AUDIO_QUESTIONS,
    SHORT_RESPONSE_AUDIO_QUESTIONS
}
