export enum ReaderAnnotationType {
    THOUGHT=1,
    CLOZE,
    HIGHLIGHT,
    HIDDEN,
    ASSESSMENT,
    FILL_THE_BLANK,
    SYNTAX,
    ACTION_CLIP,
    IMAGE_DICTIONARY,
    CITATION,
    BOOKMARK,
    QUOTATION,
    WORD_TO_PRACTICE,
    SPELLING_BEE,
    VOCABULARY,
    TIMELINE,
    POLL,
    BACKGROUND_BUILDER,
    ASSIGN_QUIZ,
    INDICATOR
}
