/** Do not change order of enum as it corresponds with thresholdLevelIds that come from the API **/
export enum ShortResponseQuestionLevel {
    BELOW = 0,
    APPROACHING,
    ON,
    ABOVE
}

export namespace ShortResponseQuestionLevel {
    export function toList(): any[] {
        const levels = Object.keys(ShortResponseQuestionLevel).filter(thresholdLevel => {
            if (!isNaN(Number(thresholdLevel))) {
                return thresholdLevel;
            }
        }).map(id => {
            return {
                index: +id,
                optionText: ShortResponseQuestionLevel[+id]
            };
        });
        levels.reverse();

        return levels;
    }
}
