export enum BookTagType {
  Author = 0,
  Genre,
  Topic,
  Language,
  ArticleProfile,
  Publisher,
  BookProfile,
  Series,
  SeriesOrder,
  Keyword,
  Country,
  Imprint,
  Category,
  Bisac,
  Prompt,
  HelpSection,
  ContentReligion,
  Century,
  Era,
  GenderOfAuthor,
  Ethnicity,
  SchoolLevel,
  AuthorType = 22,
  AuthorYearOfBirth = 23,
  AuthorYearOfDeath = 24,
  BookBackgroundTheme = 25
}
