export enum BadgeCategory {
  Genre = 1,
  ReadingAchievement,
  ReadingPower,
  FinancialLiteracy,
  Tournaments,
  SpellingBee,
  TeamReading,
  Grammar,
  Decodables
}

export namespace BadgeCategory {
  export function toList(): any[] {
    return Object.keys(BadgeCategory).filter(cat => {
      if (!isNaN(Number(cat))) {
        return cat;
      }
    }).map(x => {
      return {
        cat: Number(x),
        data: null
      };
    });
  }
}
