import { ListItem } from '../models';

export enum SearchTermType {
  Title,
  Genres,
  Topics,
  Authors,
  Series,
  Keywords,
  Subjects,
  Encyclopedia
}

export namespace SearchTermType {
  export function toList() {
    return Object.keys(SearchTermType)
      .filter((key) => !isNaN(Number(key)))
      .map<ListItem>((key) => ({
        itemId: Number(key),
        itemName: toString(Number(key) as SearchTermType),
      }));
  }

  export function toString(type: SearchTermType) {
    switch (type) {
      case SearchTermType.Title:
        return 'SEARCH.TITLE';
      case SearchTermType.Genres:
        return 'SEARCH.GENRES';
      case SearchTermType.Topics:
        return 'SEARCH.TOPICS';
      case SearchTermType.Series:
        return 'SEARCH.SERIES';
      case SearchTermType.Authors:
        return 'SEARCH.AUTHORS';
      case SearchTermType.Keywords:
        return 'SEARCH.KEYWORDS';
      case SearchTermType.Subjects:
        return 'SEARCH.SUBJECTS';
    }
  }
}
