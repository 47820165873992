import { ListItem } from '../models/list-item';

export enum ClassificationType {
  Fe_Only_All = - 1,
  Literature = 2,
  Informational = 1,
  Both = 3,
}

export namespace ClassificationType {
  export function toEnumList(): ClassificationType[] {
    return Object.keys(ClassificationType)
      .filter(option => !isNaN(Number(option)))
      .map(option => Number(option) as ClassificationType);
  }

  export function toList(): ListItem[] {
    return Object.keys(ClassificationType)
      .filter(option => {
        if (!isNaN(Number(option))) {
          return option;
        }
      })
      .map(option => {
        return {
          itemId: +option,
          itemName: ClassificationType.toString(+option),
        };
      });
  }

  export function toString(type: ClassificationType): string {
    return `REPORTS.${ ClassificationType[type].toUpperCase() }`;
  }
}
