export enum VoiceAssessmentType {
  // Read Aloud
  SPEAKING,
  // Speaking, Listening & Writing
  GENERAL,
  MEMORY
}

export namespace VoiceAssessmentType {
  export function toList(isTexas: boolean = false): { index: number, optionText: string }[] {
    return [{
      index: VoiceAssessmentType.SPEAKING,
      optionText: VoiceAssessmentType.toString(VoiceAssessmentType.SPEAKING),
    }, {
      index: VoiceAssessmentType.GENERAL,
      optionText: VoiceAssessmentType.toString(VoiceAssessmentType.GENERAL, isTexas),
    }];
  }

  export function toString(type: VoiceAssessmentType, isTexas: boolean = false): string {
    switch (type) {
      case VoiceAssessmentType.SPEAKING:
        return 'SPEAKING';
      case VoiceAssessmentType.GENERAL:
        return isTexas ? 'TELPAS' : 'GENERAL';
      default:
        return '';
    }
  }
}
