export enum SystemConfigurationTypeEnum {
  LexileGrowthTarget = 0,
  FailedLoginAttemptLimit,
  NumberOfWeeks,
  TeacherAlertThoughtCreated,
  StudentAlertThoughtComment,
  StudentAlertSRQMarked,
  StudentAlertGoalCreated,
  StudentAlertBadgeEarned,
  ExceptionalStudentAlertMessage,
  ExceptionalStudentThresholdDivisor,
  StrugglingStudentIncorrectThreshold,
  StrugglingStudentMaxSampleSize,
  StrugglingStudentAlertMessage,
  PageSessionMinimumDurationCutOff,
  MaxLowLexileDiffForBorrowedBook,
  MaxHighLexileDiffForBorrowedBook,
  LowLexileDiffAlertMessage,
  HighLexileDiffAlertMessage,
  TeacherRetaggedThoughtAlertMessage,
  TeacherTaggedThoughtAlertMessage,
  RecordThinkingReminderAlertMessage,
  RecordThinkingReminderDaysWithoutThoughtLimit,
  RecordThinkingReminderDaysWithoutAlertForRealert,
  SuccessfulBookCompletionPercentage,
  PageAmountToApplySuccessPercentage,
  StudentNotRecordedThoughtTeacherAlertDaysLimit,
  StudentNotRecordedThoughtTeacherAlertMessage,
  BookCompletedAlertMessage,
  FailedToCompleteBookAlertMessage,
  DefaultBookRating,
  MinimumBookRatingCount,
  MilestoneBadgeThreshold,
  ReadingPowerBooksCompletedGoal,
  ReadingPowerPowerTextsCompletedGoal,
  ReadingPowerLexileGrowthGoal,
  TotalDaysInSchoolYear,
  WordsReadPerMinute,
  MinuteReadPerDay,
  VocabularyBadgeIncrement,
  StudentCheckoutLimitForEnglishTexts,
  TeacherCheckoutLimit,
  MidTerm,
  BakerTaylorApiTimeBuffer,
  BookImportInterval,
  PowerTextLowerLexileThreshold,
  PowerTextUpperLexileThreshold,
  PowerTextModeEnabled,
  VocabularyWhizBadgeMinimumClozes,
  VocabularySuperstarBadgeMinimumClozes,
  VocabularyGeniusBadgeMinimumClozes,
  StudentCheckoutLimitForSpanishTexts,
  LotsOfWordsReadValue,
  InactivityTimeoutInSeconds = 52,
  IOSDefaultsForAnnotations,
  IOSDefaultsForMCQAssessments,
  IOSDefaultsForSRQAssessments,
  IOSDefaultsForClassFeed,
  IOSDefaultsForStudentPortfolio,
  IOSDefaultsForCamera,
  IOSDefaultsForListen,
  InactivityTimeoutForReaderInSeconds,
  PowerChallengeRequired = 69,
  PlacementExpirationDays = 72,
  TaggingStandard = 82,
  SchoolDayStartTime,
  SchoolDayEndTime,
  Language = 85,
  PremiumUser = 86,
  OfflineWaitTimeoutInSeconds = 87,
  MinimumPowerTextLexile = 88,
  RequiresEarlyCheckinFeedback = 89,
  AllowLexileThresholds = 90,
  AssignmentsModuleEnabled = 100,
  DemoTopicQuestions = 102,
  DemoCommunityQuestions = 103,
  DemoSyntaxWords = 104,
  DemoPledgeGifting = 105,
  CBStudentPortfolio = 106,
  SpeedTestOnBookDownload = 110,
  ClozeAssessmentControl = 111,
  PowerTextClozeAssessmentControl = 112,
  AllowPowerChallengeOutsideSchoolHours = 113,
  DelayPowerChallengeUntilDate = 114,
  AllowToModifyPowerChallengeSettings = 115,
  PowerChallengeGradeLevel = 116,
  AllowTeachersToModifyPowerChallengeSettings = 117,
  AllowTeachersToDelayPowerChallenge = 118,
  AllowTeachersToGiveStudentsPCAccessForOutsideSchoolHours = 119,
  PrivateWishList = 120,
  PowerClozeLexileLowerLimit = 121,
  PowerClozeLexileUpperLimit = 122,
  SpellingBeePretestEnabled = 123,
  VideoCreditMinutes = 124,
  VideoLibraryAccess = 125,
  ActionClipLibraryAccess = 126,
  ActionClipInBookAccess = 127,
  TimeDuringDayAccessForVideosAndActionClips = 128,
  VideoAccessDuringSchoolHours = 129,
  ActionClipAccessDuringSchoolHours = 130,
  CanTakeSpellingBeeAtBookCompletion = 131,
  SpellingBeeTestSource = 132,
  UserBackgroundTheme = 133,
  ChildNotifyOfRemindToCompleteGoals = 134,
  ChildNotifyOfParentSendMessage = 135,
  ChildNotifyOfBookHasChat = 136,
  ChildNotifyOfBookIReadHasChat = 137,
  ChildNotifyOfNewBadge = 138,
  ChildNotifyOfSiblingsCompleteGoal = 139,
  ChildNotifyOfSiblingsReceiveBadge = 140,
  AllowChildBookChat = 141,
  RestrictChatToOnlyClubHouse = 142,
  RestrictChatToOnlyBookClubs = 143,
  AllowStudentToShareUserBioInfo = 144,
  ScreenbackgroundTheme = 145,
  StudentCanWatchVideosInSchoolHours = 146,
  StudentCanWatchActionClipsInSchoolHours = 147,
  AllowVideoActionClipAccess,
  ReadingDirectionHorizontal = 149,
  AudioCreditMinutes = 152,
  AudiseeBooksEnabled = 153,
  TextToSpeechEnabled = 154,
  AudioBooksEnabled = 155,
  InteractiveBooksEnabled = 156,
  ConsumerVideoAccess = 157,
  ConsumerActionClipLibraryAccess = 158,
  ConsumerActionClipInBookAccess = 159,
  VideoInBookAccess = 160,
  RequireAppropriatenessSetting = 161, // AllowMatrixAppropriateness
  AppropriateUnratedAllowRequestAccess = 162, // MatrixRule
  UsernameApproved = 163,
  AllowFaithBasedContent = 164,
  AllowTedTalkLibraryAccess = 165,
  AllowLiveStreamLibraryAccess = 166,
  ArticleReadingDirectionVertical = 167,
  EnableStrugglingReadersMode = 168,
  ShowChildReadingGoalsOnDashboard = 169,
  AlwaysTurnOnPersonalizedReader = 170,
  PCQuestionsPerSession = 171,
  MotivationalMessagePerPCSession = 172,
  PCTextToSpeechOptions = 173,
  AllowSiblingChatAccess = 174,
  AllowAccessBehindTheHeadlines = 175,
  TopHeaderBarColor = 176,
  PersonalizedReaderEnabled = 177,
  PersonalizedReaderSettingsLocked = 178,
  PersonalizedReaderStudentAllowedTemplateTypes = 179,
  PersonalizedReaderAllowStudentToEditDefaultTemplate = 180,
  PersonalizedReaderEnableTimeOnTask = 181,
  PersonalizedReaderTimeOnTaskSetup = 182,
  PersonalizedReaderAllowedGamesAndActivities = 183,
  PersonalizedReaderTeacherTemplateLockedForEditing = 184,
  AllowStudentToSeeTeamResults,
  AllowStudentToCreatePersonalizedReaderTemplates,
  IsCviStudent,
  WordBubbleOnlyOnWordsToPractice = 188,
  PracticeWordsInReader = 189,
  PracticeWordListsInReader = 190,
  PracticeWordRulesInReader = 191,
  AllowStudentToUpdateName,
  AllowChildTournaments,
  EnableClozeAnswerDelay,
  ConsumerStrugglingReaderHideLeftMenu,
  AllowStudentToSendParentPortalInvite,
  ParentObserverAllowBuyBooks,
  AllowPowerChallengeListen,
  DokQuestionPassScore,
  ReadingStandardMasteryLevel,
  DetailedReadingStandardMastery,
  VideoLimitPerDay,
  PowerTextCompletedDays,
  AllowSpellingBees,
  AllowStudentAuthoredBooks,
  DecodableAllowSRQRecordAnswer,
  DecodableEnablePreAssessmentKnowledgeQuestion,
  DecodableEnablePostAssessmentKnowledgeQuestion,
  DecodableEnableComprehensionQuestion,
  DecodableEnableExtensionQuestion,
  EnableStudentK3DecodableUI,
  AddDecodablePunctuationPlusHelpInReader = 212,
  StudentDecodable3DReaderEnabled = 213,
  UpdateLexileFromPlacementTest = 214,
  DisplayStudentLexile,
  SpanishBooksEnabled = 216,
  AllowDecodablesLibraryDuringSchoolHours = 217,
  AllowDecodablesCinamaDuringSchoolHours = 218,
  DefaultLibraryThumbnailSize = 222,
  TeacherDecodable3DReaderEnabled = 223,
  PlacementTestTitle = 226,
  DecodablesStatusCanRead = 227,
  StudentCanAddAnnotations = 228,
  StudentVoiceRecording,
  StudentUseCamera,
  StudentLeaderboard,
  StudentLanguage,
  StudentListen,
  StudentViewPortfolio,
  StudentDisplayThinkfeed,
  StudentFreeReadingClozes = 236,
  StudentPowerClozes = 237,
  StudentMultipleChoice = 238,
  StudentShortResponse = 239,
  EnableCareerReadinessGoal = 242,
}
