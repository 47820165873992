export enum LoginProviderType {
    Username_Password = 0,
    Google,
    Clever,
    Classlink,
    JwtToken,
    ActiveDirectory,
    GG4L = 7,
    AccessToken = 8,
    // Internal Use
    IdentityToken = 20
}
