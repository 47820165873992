export enum UserType {
  Student = 0,
  Teacher = 1,
  LightSailSuperAdmin = 2,
  Importer = 3,
  LibraryManager = 4,
  Observer = 5,
  LightSailAdmin = 6,
  PartnerSuperAdmin = 7,
  PartnerAdmin = 8,
  PartnerConsultant = 10,
  Accounting = 11,
  Customer,
  Affiliate,
  MPSuperAdmin
}
