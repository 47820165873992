export enum DateFormat {
    // Date Pipe Format Reference:https://angular.io/docs/ts/latest/api/common/index/DatePipe-pipe.html
    EEEEMMMMdjm = 1, // Use Case Example: Wednesday, November 2 at 5:07 PM
    EEEEMMMMdd,      // Use Case Example: Tuesday, November 15
    MMMddy,          // Use Case Example: Oct 28, 2016
    MMdd,            // Use Case Example: 08/26
    MMM,             // Use Case Example: AUG
    MMMMdd,          // Use Case Example: August 28
    MMMy,            // Use Case Example: AUG - 2018
    MMMMy            // Use Case Example: August 2018
}
