export enum BadgeType {
  CompletedText = 0,
  PowerTexts = 1,
  ReadingPower20Minutes = 2,
  BeatTheClock = 3,
  MillionWordsRead = 4,
  MilestoneTextsRead = 5,
  ShortResponseAboveStandard = 6,
  ShortResponseOnStandard = 7,
  Genre = 8,
  Vocabulary = 9,
  VocabularyWhiz = 10,
  VocabularySuperstar = 11,
  VocabularyGenius = 12,
  WordWorkCloze = 13,
  Participation = 14,
  TopReader = 15,
  PartsOfSpeech = 16,
  SuperSaver = 17,
  MoneyManager = 18,
  FinancialSmarts = 19,
  TournamentParticipant,
  Winner,
  RunnerUp,
  ThirdPlace,
  MadeTheCut,
  PrizeWinner,
  SpellingBeeQuizCompletion,
  SpellingBeeRolling,
  SpellingBeeRetake,
  MilestoneMinutesRead,
  RoundWinner,
  TeamReadingAboveTarget,
  TeamReadingOnTarget,
  TeamReadingParticipation,
  StudentPracticedWordMasteryRoll,
  PunctuationRoll,
  PartsOfSpeechRoll,
  SyllableRoll,
  HomophonesRoll,
  ToBeVerbsRoll,
  IrregularPastTense,
  ContractionsRoll,
  ContractionsBeginnerReachedMastery,
  ContractionsIntermediateReachedMastery,
  ContractionsAdvancedReachedMastery,
  ContractionsAdvancedPlusReachedMastery,
  HomophonesBeginnerReachedMastery,
  HomophonesIntermediateReachedMastery,
  HomophonesAdvancedReachedMastery,
  HomophonesAdvancedPlusReachedMastery,
  ToBeVerbsBeginnerReachedMastery,
  ToBeVerbsIntermediateReachedMastery,
  ToBeVerbsAdvancedReachedMastery,
  ToBeVerbsAdvancedPlusReachedMastery,
  IrregularPastTenseBeginnerReachedMastery,
  IrregularPastTenseIntermediateReachedMastery,
  IrregularPastTenseAdvancedReachedMastery,
  IrregularPastTenseAdvancedPlusReachedMastery,
  PunctuationBeginnerReachedMastery,
  PunctuationIntermediateReachedMastery,
  PunctuationAdvancedReachedMastery,
  PunctuationAdvancedPlusReachedMastery,
  PartsOfSpeechBeginnerReachedMastery,
  PartsOfSpeechIntermediateReachedMastery,
  PartsOfSpeechAdvancedReachedMastery,
  PartsOfSpeechAdvancedPlusReachedMastery,
  NumberOfSyllablesBeginnerReachedMastery,
  NumberOfSyllablesIntermediateReachedMastery,
  NumberOfSyllablesAdvancedReachedMastery,
  NumberOfSyllablesAdvancedPlusReachedMastery,
  DecodableMap = 77
}
