export enum SettingKey {
    MultipleChoiceQuestionsEnabled = 1,
    ShortResponseQuestionsEnabled,
    AnnotationsEnabled,
    ClassFeedEnabled,
    PortfolioEnabled,
    CameraEnabled,
    ListenEnabled,
    ThinkFeedEnabled,
    Language,
    EnableMinutesReadWidget,
    PowerTextEnabled,
    ClozeInFreeTextEnabled,
    ClozeInPowerTextEnabled,
    EnhancedReaderEnabled,
    LineFocusLevel,
    LineFocusMode,
    ListeningMode,
    ListeningSpeed,
    TextBeforeOpacity,
    TextAfterOpacity = 20,
    FontFace,
    Theme,
    FontSize,
    Voice,
    Color,
    VoiceThoughts,
    OrderCheckout,
    ConsumerCameraEnabled = 28,
    EnglishONLY = 29,
    AudiseeBooksEnabled = 30,
    TextToSpeechEnabled = 31,
    AudioBooksEnabled = 32,
    InteractiveBooksEnabled = 33,
    AllowSiblingsChatForFamily = 34,
    UserDashboardWidgets = 35,
    AutoAssignSpellingBee,
    FreeSetting2 = 37,
    FreeSetting3 = 38,
    FreeSetting4 = 39,
    FreeSetting5 = 40,
  FeOnlyDokScore = -1,
  FeOnlyReadingSkillsMasteryLevel = -2
}
