export enum BookFormatEnum {
  EPUB = 1,
  PDF,
  Video,
  ActionClip,
  Encyclopedia,
  AudioEpub,
  Audio,
  Interactive,
  AudioEpub2,
  AugmentedReality,
  Image,
  Image360,
  LiveStream,
  Table,
  Map,
  TedTalk,
  HelpVideo,
  Magazine,
  Decodables,
  Collections
}

export namespace BookFormatEnum {
  export function toEnumList(): BookFormatEnum[] {
    return Object.keys(BookFormatEnum)
      .filter(option => !isNaN(Number(option)))
      .map(option => Number(option) as BookFormatEnum);
  }
}

export namespace BookFormatEnum {
  export function toList(): BookFormatEnum[] {
    return Object.keys(BookFormatEnum)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => Number(key));
  }
}
