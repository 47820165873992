export enum DialogButtonAction {
  YES = 1,
  NO,
  CANCEL,
  OK,
  DISMISS,
  UPGRADE,
  REMOVE,
  SAVE,
  SUBMIT,
  ADD,
  ACCEPT_SCORE,
  RESET,
  YES_I_WANT_EXIT,
  YES_DELETE,
  YES_MAKE_IT_LIVE,
  NO_LATER,
  YES_HIDE_THE_BOOK,
  YES_HIDE_THE_VIDEO,
  YES_REMOVE_FROM_CHAT,
  YES_REMOVE_CHAT_ACCESS_FOR_ALL,
  YES_JOIN_BOOK_CLUB,
  YES_REMOVE_FROM_BOOK_CLUB,
  YES_ADD_TO_BOOK_CLUB_CHAT,
  YES_REMOVE_FROM_MY_BOOK_CLUB,
  YES_DELETE_IT,
  YES_COPY,
  GOT_IT,
  LEAVE_APP,
  CONTINUE_TO_THE_APP,
  NO_RETURN_TO_MY_WRITING,
  NO_REVIEW_AGAIN,
  YES_CREATE_BOOKMARK,
  YES_CREATE_QUOTATION,
  YES_I_AM_SURE,
  NO_I_WANT_TO_REVIEW_AGAIN,
}

