export enum AlertType {
  StudentSrqGraded = 0,
  ThoughtComment,
  StudentGoalAdded,
  ThoughtStandardTagged,
  ThoughtStandardRetagged,
  StudentBadgeReceived,
  ConferenceNotification,
  BenchmarkNotification,
  GroupNotification,
  ReadingBelowLexile,
  ReadingAboveLexile,
  PowerTestSelection,
  ThinkFeedContribution,
  StrugglingWithAssessment,
  StudentWithPerfectScore,
  StudentRepliedToTeacherThought,
  StudentUsingListenFeature,
  StudentRespondedToSRQFeedback,
  ExceptionalStudent,
  ThoughtCreated,
  StrugglingStudent,
  LowBorrowedBookLexile,
  HighBorrowedBookLexile,
  TeacherTaggedThought,
  RecordThinkingReminderAlert,
  ThoughtCommentDeleted,
  ThoughtDeleted,
  StudentNotRecordedThoughtTeacherAlert,
  BookCompleted,
  FailedToCompleteBook,
  LexileIncreased,
  LexileDecreased,
  StrugglingWithCloze,
  TeacherReTaggedThought,
  HomeworkDue,
  ParticipatingInTournament,
  TournamentStarting,
  TournamentStarted,
  TournamentLeaderboardEnding,
  TournamentHybridLeaderboardResult,
  TournamentKnockoutRoundStarting,
  TournamentKnockoutRoundStarted,
  TournamentKnockoutRoundEnding,
  TournamentKnockoutRoundResult,
  TournamentResult,
  TournamentPrizeAwarded,
  UserBookPublished = 47,
  UserBookAddedAsPeerReviewer = 48,
  UserBookStatusInReview = 49,
  StudentUserBookCommentAdded = 50,
  TeacherUserBookCommentAdded = 51,
  StudentPublishedBookLikeAdded = 52,
  PeerReviewerUserBookCommentAdded = 53,
  PeerReviewerCompletedReview = 54,
  TeacherAssignedLibraryBook = 55,
  ChildRetakeClozesAlert = 56,
  ChildRetakeSpellingBeeAlert = 57,
  ChildMotivationalMessage = 58,
  CheckoutsRemainingAlert = 59,
  StudentAchievedMilestone = 60,
  StudentAuthenticWritingSelectedPrompt = 61,
  StudentAuthenticWritingSubmittedForReview = 62,
  StudentAuthenticWritingAddedComment = 63,
  TeacherAuthenticWritingAddedComment = 64,
  StudentAuthenticWritingSentReminderToGrade = 65,
  TeacherAuthenticWritingSentReminderToCompleteAssignment = 66,
  TeacherAuthenticWritingGradedAssignment = 67,
  ChildResumeSpellingBeeAlert = 68,
  ParentActivatedAbandonedAlert = 69,
  MinutesReadGoalMotivationalMessage = 70,
  BooksReadMilestoneMotivationalMessage = 71,
  MinutesReadMilestoneMotivationalMessage = 72,
  StudentGiftGoalCreated = 73,
  TeacherAssignedVideo = 74,
  BookClubNewRequest = 75,
  BookClubRequestAccepted = 76,
  RequestToReadBook = 77,
  RequestToReadBookApproved = 78,
  RequestToReadBookDeclined = 79,
  ChildTakeAssignmentAlert = 80,
  InvitedToTournament = 81,
  SpellingBeeAssigned = 82,
  TournamentCompetitorNotification = 83,
  TournamentUpcoming = 84,
  SharePersonalisedReaderTemplate = 85,
  BooksEarnedFromInvites = 86,
  StudentPracticeWordMovedToMasteredList = 87,
  StudentPracticeWordsReachedMasteryCount = 88,
  ChildJoinedTournament = 89
}
