export enum Grade {
  GradeK = 0,
  Grade1,
  Grade2,
  Grade3,
  Grade4,
  Grade5,
  Grade6,
  Grade7,
  Grade8,
  Grade9,
  Grade10,
  Grade11,
  Grade12
}

export namespace Grade {
  export function toSeria(g: Grade): string {
    switch (g) {
      case Grade.GradeK:
        return 'K';
      case Grade.Grade1:
        return '1st';
      case Grade.Grade2:
        return '2nd';
      case Grade.Grade3:
        return '3rd';
      default:
        return (<number>g).toString() + 'th';
    }
  }

  export function toList(): Grade[] {
    return Object.keys(Grade)
      .filter(grade => Number(grade) >= 0)
      .map(optionObject => +optionObject);
  }
}


