export enum SettingType {
  Account = 0,
  Annotations,
  Assessments,
  ClassFeed,
  Camera,
  Listen,
  Portfolio,
  ThinkFeed,
  WatchList,
  Language,
  ClassLeaderboard,
  DefaultPassword,
  EnhancedReader,
  PowerText,
  Goals,
  HiddenTexts,
  VideosAndActionClips,
  OrderCheckout,
  AllowSiblingsChatForFamily,
  PersonalizedReader,
  Tournaments,
  CviStudent,
  ParentPortalInvites,
  AssessmentsMagazine,
  LogManualMinutes,
  SpellingBees,
  StudentAuthoredBooks,
  DecodablesPlus,
  Lexile,
  CareerReadiness
}
